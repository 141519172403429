.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#basic-navbar-nav {
  justify-content: end;
}
.Carlos{
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap;
    font-weight: 700;
    color: #000000;
}
.About{
  display: flex;
  flex-direction: column;
  font-size: xxx-large;
  font-weight: 700;
  margin-top: 230px;
}
.Hero-container{
  display: flex;
  flex-direction: column;
  font-size: xxx-large;
  font-weight: 700;
  
}
.Myname{
  display:flex;
  flex-direction: column;

 }
 .myNameh1{
   font-size: 3.5rem;
   font-weight: 500;
 }
 .Icons{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  /* border-color: blue;
  border-style: dotted; */
  background-color: cornflowerblue;
  border-radius: 37px;
  height: 387px;
  box-shadow: 0 1rem 2rem hsl(0deg 0% 0% / 20%);
}
 .Aboutme{
  display:flex;
  flex-direction: column;
  /* border-color: green;
  border-style: dotted; */
  margin-top: 100px;
  /* border: 1px solid #dfe1e5;
  box-shadow: 0 1rem 2rem hsl(0deg 0% 0% / 20%); */
}
 .h2-title{
  display: flex;
  justify-content: left;
 }
 .p-about{
  font-size: 1rem;
  font-weight: 400;
  color: rgb(105, 105, 105);
  text-align: initial;

  
 }
 .Skills{
  display:flex;
  flex-direction: column;
  /* border-color: orange;
  border-style: dotted; */
  margin-top: 100px;
 }
 /* .Skills2{
  font-size: 1.0rem;
  text-align: justify;
  color: rgb(105, 105, 105);
 } */
 .Contactme{
  display:flex;
  flex-direction: column;
  /* border-color: orange;
  border-style: dotted; */
  margin-top: 100px;
 }
 .C-email{
  display: flex;
  text-decoration: none;
  font-size: 1.5rem;
  color: black;
}
.B-nav{
  font-size: 1.0rem;
  margin-top:150px;
}
.Madeby{
  font-size: 1.0rem;
  color: rgb(105, 105, 105);
}


 @media (min-width: 992px){
   .Hero-container{
    flex-direction: row;
   }
   .Myname{
     margin-top: 150px;
   }
 }
 




